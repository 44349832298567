

  .progress-bar2 {
    position: relative;
    top: 80px;
    left: 30px;
    width: 270px;
    height: 35px;
    background-color: #72D5F4;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .progress-bar-fill2 {
    height: 100%;
    background-color: #80c8ff;
    border-radius: 15px;
    transition: width 0.1s ease-in-out;
  }
  
  .slider2 {
    position: absolute;
    top:0px;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #80c8ff;
    transition: left 0.1s ease-in-out;
  }
  
  .range-info2 {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .kw-display2 {
    position: relative;
    left: 140px;
    margin-top: 50px;
    font-size: 15px;
    background-color: #000;
    padding: 5px 10px;
    border-radius: 10px;
    display: inline-block;
    min-width: 40px;
  }
  